* {
    font-family: 'Roboto', sans-serif;
    transition: 0.2s;
}

.main-content {
    padding-top: 40px;
    padding-bottom: 40px;
}

.main-content-mobile {
    padding-bottom: 80px;
}

.center-screen {
    margin: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.sidebar-button-collapsed {
    height: 40px;
    width: 44px;
}

.navbar-custom {
    height: 40px;
    background-color: #333;
}

.navbar-bot {
    height: 60px;
}

.modal-button-width {
    width: 110px !important;
}

.mobile-button-width {
    width: 130px !important;
}

.large-button-width {
    width: 160px !important;
}

.fw-medium {
    font-weight: 500 !important;
}

.fw-heavy {
    font-weight: 700 !important;
}

.bg-sidebutton {
    background-color: transparent !important;
    border: 0px !important;
}

.bg-sidebutton-active {
    border: 0px !important;
    border-left: 2px solid #7444ff !important;
}

.fs-7 {
    font-size: 0.95rem !important;
}

.fs-8 {
    font-size: 0.8rem !important;
}

.text-main {
    color: #7444ff !important;
}

.text-gray {
    color: #464646 !important;
}

.text-off-white {
    color: #959595 !important;
}

.bg-main-1 {
    background-color: #7444ff !important;
}

.bg-main-2 {
    background-color: #8961ff !important;
}

.bg-main-3 {
    background-color: #581fff !important;
}

.bg-primary-dark {
    background-color: #4f28b0 !important;
}

.bg-dark-1 {
    background-color: #333333 !important;
}

.bg-dark-2 {
    background-color: #454545 !important;
}

.bg-dark-3 {
    background-color: #2e2e2e !important;
}

.bg-dim-dark {
    background-color: rgba(0, 0, 0, 0.15) !important;
}

/*
CUSTOM SCROLLBAR
*/

/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #7444ff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #7444ff;
}

/*
REACT-DATE-PICKER Custom styling
*/
.react-date-picker {
    display: inline-flex;
    position: relative;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-date-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
}
.react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
}
.react-date-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;
}
.react-date-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
}
.react-date-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    border: 0;
    border-radius: 1em;
    padding: 0 0.5em;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
    background: rgba(0, 0, 0, 0.01);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
}
.react-date-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
}
.react-date-picker__button:enabled {
    cursor: pointer;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: #7444ff;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
    stroke: #6d6d6d;
}
.react-date-picker__button svg {
    display: inherit;
}
.react-date-picker__calendar {
    width: 350px;
    max-width: 100vw;
    z-index: 1;
}
.react-date-picker__calendar--closed {
    display: none;
}
.react-date-picker__calendar .react-calendar {
    border-width: thin;
}

.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border-radius: 1em;
    line-height: 1.125em;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1) !important;
}
.react-calendar--doubleView {
    width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
    border-radius: 1em;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}
.react-calendar__navigation button {
    min-width: 44px;
    background: none;
    border-radius: 1em;
}
.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
    /* color: #7444FF; */
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
}
.react-calendar__tile:disabled {
    background-color: rgba(0, 0, 0, 0.01);
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__tile--now {
    background: #8961ff;
    color: white;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #7444ff;
}
.react-calendar__tile--hasActive {
    background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}
.react-calendar__tile--active {
    background: #006edc;
    color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #7444ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}
