@keyframes rotate {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  
  .rotate {
    animation: rotate 5s linear infinite;
  }