.signin-box {
    width: 560px !important;
}

.signin-button-height {
    height: 40px !important;
}

.bg-overlay {
    background: url('../../../public/bg-home-tech-ui-kit.svg') no-repeat center center fixed;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}
