.dropzone {
    border: 2px dashed #cccccc;
}

.dropzone.active {
    border: 2px dashed #2196f3;
}

.main-div {
    background-color: #f2f6fc;
}

.three-dot-icon {
    cursor: pointer;
    transform: rotate(90deg);
}

.main-div:focus,
.main-div.focused {
    background-color: #f2f6fc;
}

.main-div:hover {
    background-color: #e1e5ea;
}

.main-div.selected {
    background-color: #c2e7ff;
}

.main-div.selected:hover {
    background-color: #b5e1ff;
}
